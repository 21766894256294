import useDeviceDetect from 'src/common/hooks/useDeviceDetect'

import { HeroContent, HeroContentFooter, HeroImage } from '../Hero'
import AmountExample from '../AmountExample'
import { useMemo } from 'react'

export const SectionB = ({
  data,
  getSuperScriptNumber,
  onConversion = () => {},
}) => {
  const { isMobile } = useDeviceDetect()
  const ctaSection = useMemo(() => {
    return (
      <button
        onClick={(e) => onConversion(e)}
        className="mt-4 w-full rounded-lg bg-blue-600 px-[24px] py-[10px] text-lg font-bold leading-snug tracking-normal text-white lg:mt-4 lg:w-fit"
      >
        {data.buttonCtaText}
      </button>
    )
  }, [data, isMobile, onConversion])

  return (
    <div className="lg:w-content m-auto my-primary w-full lg:my-primary-xl lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
      <div className="lg:order-first lg:m-auto lg:ml-[6rem] lg:max-w-[560px]">
        <div className="lg:pl-secondary lg:pr-5.4">
          <h2 className="text-heading-4 font-bold leading-23 lg:text-heading-3">
            {data.title}
          </h2>
          <p className="mb-primary-md mt-redesign-xs text-14 lg:pr-0 lg:text-16">
            {data.description}
          </p>
          {!isMobile && ctaSection}
        </div>
      </div>
      <div className="lg:flex lg:items-center lg:justify-center">
        <div>
          <HeroImage
            className="mb-primary-md"
            srcMobile={data.hero.imageUrlMobile}
            srcDesktop={data.hero.imageUrlDesktop}
            alt={data.hero.imageAlt}
          >
            <HeroContent
              title={data.hero.title + getSuperScriptNumber()}
              className="mb-primary-md w-[283px] lg:ml-[-7rem] lg:mt-[-7.2rem] lg:w-[360px] lg:pb-secondary-xs"
            >
              <div className="px-2 pb-1 pt-2 lg:p-secondary-sm">
                {data.examples.map((example, index) => (
                  <AmountExample
                    key={example.title}
                    title={example.title}
                    progress={example.progress}
                    progressColor={example.color}
                    amount={example.amount}
                    className="mb-primary-xxs"
                  />
                ))}
              </div>
              <HeroContentFooter>
                <span className="text-12 text-gray-555 lg:text-13">
                  {data.examplesFooter.text}
                </span>
                <b className="text-green-555 text-14 lg:text-15">
                  {data.examplesFooter.amount}
                </b>
              </HeroContentFooter>
            </HeroContent>
          </HeroImage>
          {!!isMobile && ctaSection}
        </div>
      </div>
    </div>
  )
}

export default SectionB
