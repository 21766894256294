import { useAnalytics } from 'src/common/analytics'
import { useIntersectionObserver } from 'src/common/hooks/useIntersectionObserver'

import SectionA from './Sections/SectionA'
import SectionB from './Sections/SectionB'
import SectionC from './Sections/SectionC'

import { __experimental_howItWorksData } from './consts'
import { useCallback } from 'react'
import { useNavigateToApply } from 'src/common/hooks/useNavigateToApply'

const sectionId = 'how-it-works'

export const HowItWorks = ({
  data = __experimental_howItWorksData,
  variation,
}) => {
  const { track } = useAnalytics()
  const navigateToApply = useNavigateToApply()

  const handleOnConversion = (e) => {
    const variationText = e.target.innerText
    const data = {
      nav_link_section: 'Page',
      page_section: 'howItWorks',
      click_type: 'Button Click',
      click_id: variationText,
      click_text: `FDR-Web | ${variationText}`,
      track_event: 'button_click',
      additional_info: { section: sectionId },
    }

    track(
      data,
      {
        event_type: 'conversion',
      },
      'button_click'
    )

    navigateToApply()
  }

  const { ref } = useIntersectionObserver({
    threshold: 0.5,
    onChange: (isIntersecting) => {
      if (!isIntersecting) return
      const data = {
        nav_link_section: 'How it works',
        track_event: 'section_view',
        additional_info: { section: sectionId },
        event_type: 'track',
      }
      track(data, {}, 'section_view')
    },
    freezeOnceVisible: true,
  })

  const getSuperScriptNumber = useCallback(
    () =>
      ['variant-2', 'variant-3', 'variant-5', 'variant-6'].includes(variation)
        ? '¹'
        : '²',
    [variation]
  )

  return (
    <div
      className="lg:w-content m-auto max-w-375 px-secondary pt-primary lg:max-w-max lg:pt-primary-lg"
      id={sectionId}
    >
      <div className="text-center">
        <div
          ref={ref}
          className="pb-primary-xxs text-14 font-bold uppercase tracking-vast"
        >
          {data.title}
        </div>
        <h2 className="text-heading-3 font-bold">{data.heading}</h2>
      </div>
      <SectionA data={data.section1} onConversion={handleOnConversion} />
      <SectionB
        data={data.section2}
        getSuperScriptNumber={getSuperScriptNumber}
        onConversion={handleOnConversion}
      />
      <SectionC
        data={data.section3}
        getSuperScriptNumber={getSuperScriptNumber}
        onConversion={handleOnConversion}
      />
    </div>
  )
}

export default HowItWorks
