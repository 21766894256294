import { HeroContent, HeroImage } from '../Hero'
import styles from '../index.module.scss'

import useDeviceDetect from 'src/common/hooks/useDeviceDetect'
import { useMemo } from 'react'

export const SectionA = ({ data, onConversion = () => {} }) => {
  const { isMobile } = useDeviceDetect()
  const ctaSection = useMemo(() => {
    return (
      <button
        onClick={(e) => onConversion(e)}
        className="mt-12 w-full rounded-lg bg-blue-600 px-[24px] py-[10px] text-lg font-bold leading-snug tracking-normal text-white lg:mt-8 lg:w-fit"
      >
        {isMobile ? data.buttonCtaTextMobile : data.buttonCtaTextDesktop}
      </button>
    )
  }, [data, isMobile, onConversion])

  return (
    <div className="lg:w-content m-auto mt-primary-md w-full lg:my-primary-lg lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
      <div className="lg:order-last lg:max-w-[560px] lg:pl-primary-lg">
        <div className="lg:mx-secondary-sm lg:pr-primary">
          <h2 className="text-heading-4 font-bold leading-23 lg:text-heading-3">
            {data.title}
          </h2>
          <p className="mb-primary-md mt-redesign-xs text-14 lg:text-15">
            {isMobile ? data.descriptionMobile : data.descriptionDesktop}
          </p>
          {!isMobile && ctaSection}
        </div>
      </div>
      <div className="lg:flex lg:items-center lg:justify-center">
        <div>
          <HeroImage
            srcMobile={data.hero.imageUrlMobile}
            srcDesktop={data.hero.imageUrlDesktop}
            alt={data.hero.imageAlt}
          >
            <HeroContent
              title={data.hero.title}
              className="lg:mr-[-8rem] lg:mt-[-5.5rem] lg:w-[353px]"
            >
              <ul className={styles.programOffersList}>
                {data.hero.items.map(({ title, headline }) => {
                  const headlineClassName = 'text-14 lg:text-16'

                  return (
                    <li key={title}>
                      {headline ? (
                        <h2 className={headlineClassName}>{title}</h2>
                      ) : (
                        <span className={headlineClassName}>{title}</span>
                      )}
                    </li>
                  )
                })}
              </ul>
            </HeroContent>
          </HeroImage>
          {!!isMobile && ctaSection}
        </div>
      </div>
    </div>
  )
}

export default SectionA
