import useDeviceDetect from 'src/common/hooks/useDeviceDetect'

import { HeroContent, HeroContentFooter, HeroImage } from '../Hero'
import AmountExample from '../AmountExample'
import { Button } from 'src/components/shared/Button'
import { useMemo } from 'react'

export const SectionC = ({ data, getSuperScriptNumber, onConversion }) => {
  const { isMobile } = useDeviceDetect()

  const ctaSection = useMemo(() => {
    return (
      <div className="lg:max-w-275">
        <button
          onClick={(e) => onConversion(e)}
          className="mt-4 w-full rounded-lg bg-blue-600 px-[24px] py-[10px] text-lg font-bold leading-snug tracking-normal text-white lg:mt-4"
        >
          {data.buttonCtaText}
        </button>
        <Button
          className="mt-1"
          color="secondary"
          href={data.buttonCtaWhiteLink}
        >
          <span className="text-blue-585">{data.buttonCtaWhiteText}</span>
        </Button>
        <div className="mb-primary-md text-11 leading-5 text-gray-235">
          {data.footerComment}
        </div>
      </div>
    )
  }, [data])

  return (
    <div className="lg:w-content m-auto mt-primary-md w-full lg:my-primary-lg lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
      <div className="lg:order-last lg:ml-[5rem] lg:max-w-[560px]">
        <div className="lg:mx-secondary-sm lg:pr-primary">
          <h2 className="text-heading-4 font-bold leading-23 lg:text-heading-3">
            {data.title}
          </h2>
          <p className="mb-primary-md mt-redesign-xs text-14 lg:my-primary-md lg:pr-primary-lg lg:text-15">
            {data.description}
          </p>
          {!isMobile && ctaSection}
        </div>
      </div>
      <div className="lg:flex lg:items-center lg:justify-center">
        <div>
          <HeroImage
            className="mb-primary-md"
            srcMobile={data.hero.imageUrlMobile}
            srcDesktop={data.hero.imageUrlDesktop}
            alt={data.hero.imageAlt}
          >
            <HeroContent
              title={data.hero.title + getSuperScriptNumber()}
              className="mb-primary-md w-[283px] pb-secondary-xs lg:mr-[-8.8rem] lg:mt-[-6rem] lg:w-[353px]"
            >
              <div className="pb-1 pt-2 lg:p-secondary-sm lg:pl-0">
                {data.examples.map((example, index) => (
                  <AmountExample
                    key={example.title}
                    title={example.title}
                    progress={example.progress}
                    progressColor={example.color}
                    amount={example.amount}
                    className="mb-primary-xxs lg:pb-1 lg:text-15"
                  />
                ))}
              </div>
              <HeroContentFooter>
                <b className="text-green-555 text-14 lg:p-2 lg:text-15">
                  {data.examplesFooter}
                </b>
              </HeroContentFooter>
            </HeroContent>
          </HeroImage>
          {!!isMobile && ctaSection}
        </div>
      </div>
    </div>
  )
}

export default SectionC
