import PropTypes from 'prop-types'

const HeroContentFooter = ({ children }) => {
  return (
    <div className="flex flex-col bg-green-40 p-1 text-center">{children}</div>
  )
}

HeroContentFooter.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HeroContentFooter
