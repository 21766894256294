import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import Image from 'next/image'
import classNames from 'classnames'

import useDeviceDetect from 'src/common/hooks/useDeviceDetect'

const HeroImage = ({ className, srcMobile, srcDesktop, alt, children }) => {
  const { isMobile } = useDeviceDetect()

  return (
    <div className={classNames('relative', className)}>
      <LazyLoad>
        <Image
          src={isMobile ? srcMobile : srcDesktop}
          width={isMobile ? 335 : 394}
          height={isMobile ? 171 : 317}
          alt={alt}
        />
      </LazyLoad>
      {children}
    </div>
  )
}

HeroImage.propTypes = {
  className: PropTypes.string,
  srcMobile: PropTypes.string.isRequired,
  srcDesktop: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default HeroImage
