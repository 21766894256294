import classNames from 'classnames'
import PropTypes from 'prop-types'

export const HeroContentTitle = ({ children, className }) => (
  <h4
    className={classNames(
      'border-b px-secondary-xs py-secondary-sm pb-primary-xxs text-16 font-[500] lg:text-heading-4 lg:font-bold',
      className
    )}
  >
    {children}
  </h4>
)

HeroContentTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

const HeroContent = ({ children, title, className }) => {
  return (
    <div
      className={classNames(
        'mb-6.5 relative z-30 m-auto mt-[-3.5rem] w-[300px] rounded-[10.8px] bg-white shadow-howItWorksSection',
        className
      )}
    >
      <h2 className="border-b px-secondary-xs py-secondary-sm pb-primary-xxs text-16 font-[500] lg:text-heading-4 lg:font-bold">
        {title}
      </h2>
      {children}
    </div>
  )
}

HeroContent.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default HeroContent
