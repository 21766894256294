import PropTypes from 'prop-types'
import ButtonLink from '../ButtonLink'
import classNames from 'classnames'

export const Button = ({ color = 'primary', className, ...props }) => {
  return (
    <ButtonLink
      className={classNames(
        'mb-4 inline-block w-full rounded-lg bg-blue-585 px-12 py-2.5 text-lg font-bold text-white lg:px-6',
        color === 'secondary' &&
          'border-2 border-blue-585 !bg-white text-blue-585',
        className
      )}
      {...props}
    />
  )
}

Button.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
}
