import classNames from 'classnames'
import PropTypes from 'prop-types'

export const AmountExample = ({
  title,
  progress,
  amount,
  progressColor,
  className,
}) => {
  return (
    <div
      className={classNames(
        'flex items-center justify-center',
        !!progress ? 'flex-col' : 'flex-row',
        className
      )}
    >
      <div
        className={classNames(
          'mx-secondary-sm w-full lg:mb-1',
          !!progress ? 'text-10' : 'text-12',
          'lg:text-15'
        )}
      >
        {title}
      </div>
      {!!progress && (
        <div className="mt-[-4px] flex w-full flex-row items-center justify-center gap-3">
          <div
            className="mr-1 w-full rounded-6.9 bg-[#EEF0F9]"
            style={{ height: '6.29px' }}
          >
            <div
              className="rounded-6.9"
              style={{
                width: `${progress}%`,
                backgroundColor: progressColor,
                height: '100%',
              }}
            />
          </div>
          <span className="text-xxs font-bold lg:text-15">{amount}</span>
        </div>
      )}
      {!progress && (
        <div className="pr-secondary-sm">
          <span className="text-13 font-bold lg:text-15">{amount}</span>
        </div>
      )}
    </div>
  )
}

AmountExample.propTypes = {
  title: PropTypes.node.isRequired,
  amount: PropTypes.node.isRequired,
  progress: PropTypes.number,
  progressColor: PropTypes.string,
  className: PropTypes.string,
}

AmountExample.defaultProps = {
  className: '',
}

export default AmountExample
