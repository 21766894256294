export const __experimental_howItWorksData = {
  title: 'How it works',
  heading: 'Get rid of debt for less than you owe',
  section1: {
    title: "1. Get evaluated for America's top debt relief program",
    descriptionMobile:
      'Apply online or talk with a Certified Debt Consultant to get a custom debt plan to pay off multiple debts faster than minimums.',
    descriptionDesktop:
      'Our expert debt negotiators work with your creditors to reduce and resolve your debt in 24-48 months. Apply online or talk with a Certified Debt Consultant to get a custom debt plan to pay off multiple debts faster than minimums.',
    hero: {
      imageUrlMobile: '/next-assets/brand-pages/home/v2/step-1.mobile.webp',
      imageUrlDesktop: '/next-assets/brand-pages/home/v2/step-1.desktop.webp',
      imageAlt:
        'Two happy persons doing workout and talking about debt relief program',
      title: 'Our program offers',
      items: [
        { title: 'Credit card debt relief', headline: true },
        { title: 'Medical debt relief', headline: true },
        { title: 'Personal loans relief' },
        { title: 'Some private student loan relief' },
      ],
    },
    buttonCtaTextMobile: 'Get started',
    buttonCtaTextDesktop: 'Get your free evaluation',
    buttonCtaLink:
      'https://apply.freedomdebtrelief.com/?from=fdr&leadId=55f8c95c-d21a-4605-9d5e-0779b37b32bb',
  },
  section2: {
    title: '2. Make one low monthly deposit',
    description:
      'Free up cash by making one affordable monthly program deposit. Build up funds used to negotiate with your creditors, so you can pay debt for less.',
    hero: {
      imageUrlMobile: '/next-assets/brand-pages/home/v2/step-2.mobile.webp',
      imageUrlDesktop: '/next-assets/brand-pages/home/v2/step-2.desktop.webp',
      imageAlt: 'A mother feeling safe and secure with her child',
      title: 'Pay less each month (example)',
    },
    examples: [
      {
        title: 'Current minimun payments (with interest)',
        amount: '$729/mo',
        progress: 100,
        color: '#E44A6E',
      },
      {
        title: 'Freedom Debt Relief',
        amount: '$470/mo',
        color: '#435ACF',
        progress: 72,
      },
    ],
    examplesFooter: {
      text: 'Free up',
      amount: '$259 each month',
    },
    buttonCtaText: 'Check for a lower payment',
    buttonCtaLink:
      'https://apply.freedomdebtrelief.com/?from=fdr&leadId=55f8c95c-d21a-4605-9d5e-0779b37b32bb',
  },
  section3: {
    title: '3. Our experts negotiate debt for you',
    description:
      'Our team of 1,000+ debt experts work with you and 3,000+ creditors, so you can reduce your total debt.',
    hero: {
      imageUrlMobile: '/next-assets/brand-pages/home/v2/step-3.mobile.webp',
      imageUrlDesktop: '/next-assets/brand-pages/home/v2/step-3.desktop.webp',
      imageAlt: "An old woman happy because it's receiving debt coaching",
      title: 'Reduce what you owe (example)',
    },
    examples: [
      {
        title: 'Est. total debt cost (with interest)',
        amount: '$41,047',
      },
      {
        title: 'Est. total debt cost (with Freedom)',
        amount: '$22,543',
      },
    ],
    examplesFooter: 'Reduce your total debt cost ',
    buttonCtaText: 'Get started',
    buttonCtaLink:
      'https://apply.freedomdebtrelief.com/?from=fdr&leadId=55f8c95c-d21a-4605-9d5e-0779b37b32bb',
    buttonCtaWhiteText: 'Learn how it works',
    buttonCtaWhiteLink: 'https://www.freedomdebtrelief.com/how-it-works/',
    footerComment:
      '*Images shown above are actual member data. Individual results are not typical and will vary.',
  },
}
